import React, { useEffect, useRef } from "react";
import Animate from "../Components/Animate";
import { Outlet } from "react-router-dom";

import { Address } from "../Components/WalletDetails";
import { useUser } from "../context/userContext";

const Wallet = () => {
  const { openInfoTwo, setOpenInfoTwo } = useUser();

  const infoRefTwo = useRef(null);

  const handleClickOutside = (event) => {
    if (infoRefTwo.current && !infoRefTwo.current.contains(event.target)) {
      setOpenInfoTwo(false);
    }
  };

  useEffect(() => {
    if (openInfoTwo) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [openInfoTwo]);

  return (
    <>
      <Animate>
        <div className="w-full pt-8 justify-center flex-col space-y-3 px-5">
          <div className="w-full flex justify-center items-center flex-col space-y-3">
            <img
              alt="daxy"
              src="/maxitap.webp"
              className="w-[160px] animate-spin spinso"
            />

            <div className="w-full text-center flex flex-col items-center justify-center space-y-2">
              <h1 className="font-bold text-[32px] text-center">Airdrop Tasks</h1>
              <p className="text-[14px] text-[#c6c6c6] leading-[24px] px-6 pb-8">
                Listing soon, Complete the tasks below to participate in the
                Airdrop.
              </p>
              <div className="w-full flex flex-col">
                <Address />
              </div>
            </div>
          </div>
        </div>
        <Outlet />
      </Animate>
    </>
  );
};

export default Wallet;

